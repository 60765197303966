import FlagArabic from "assets/images/flag-arabic.png";
import FlagEnglish from "assets/images/flag-english.png";
import FlagItalian from "assets/images/flag-italian.png";

export const availableLanguages = [
  { code: "en", name: "English", flag: FlagEnglish },
  { code: "ar", name: "Arabic", flag: FlagArabic },
  { code: "it", name: "Italian", flag: FlagItalian },
];
export const getLanguageFlag = (code) =>
  availableLanguages.find((l) => l.code === code).flag;
