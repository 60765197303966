import React, { startTransition, useEffect } from "react";
import { Box, GhostIconButton, Icon, Text } from "components";
import Drawer from "components/smaltOverwritten/Drawer";
import { SHOW_LANGUAGE_FLAGS } from "configs/constants";
import useSwipe from "hooks/useSwipe";
import useWindowDimensions from "hooks/windowDimensions";
import { clamp } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import {
  languageDrawerSelector,
  setIsOpen,
} from "redux/slices/languageDrawer.slice";
import { availableLanguages } from "utils/language.util";

const drawerHeaderOffset = 80; //px
const LanguageSelectionOptionHeight = 56; //px

const LanguageDrawer = ({ refetchFlights, refetchSettings }) => {
  const { i18n } = useTranslation();
  const currentLanguageCode = i18n.language;

  const { data: settingsData = {} } = useGetSettingsQuery(undefined, {
    refetchOnReconnect: true,
  });

  // Filter available languages based on settings
  const filteredLanguages = availableLanguages.filter((lang) =>
    settingsData.availableLanguages?.some(
      (settingLang) => settingLang.code === lang.code
    )
  );

  const { height } = useWindowDimensions();

  const minimumContentHeight =
    filteredLanguages.length * LanguageSelectionOptionHeight +
    drawerHeaderOffset;

  const { isOpen: isLanguageDrawerOpen } = useSelector(languageDrawerSelector);
  const dispatch = useDispatch();

  const {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    translationAmount,
    setTranslationAmount,
  } = useSwipe(isLanguageDrawerOpen);

  const handleLanguageClick = async (languageCode) => {
    if (languageCode === currentLanguageCode) {
      return;
    }

    // Forces a new language call to the BE
    await i18n.reloadResources(languageCode);

    startTransition(() => {
      i18n.changeLanguage(languageCode);
      // Forces a new flight call and a new settings call to the BE because the language has changed and the flights labels need to be updated accordingly and the terminal dropdown needs to be updated.
      refetchFlights();
      refetchSettings();
    });
  };

  useEffect(() => {
    if (!isLanguageDrawerOpen) {
      setTranslationAmount(0);
    }
  }, [isLanguageDrawerOpen, setTranslationAmount]);

  return (
    <>
      <Drawer
        isOpen={isLanguageDrawerOpen}
        translationAmount={clamp(translationAmount, 0, height - height / 3)}
        minimumContentHeight={minimumContentHeight}
      >
        <Box d="flex" direction="column" alignItems="center" bB>
          <Box
            h="24px"
            d="flex"
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <Box
              w="40px"
              h="4px"
              backgroundColor="resizeHandle"
              borderRadius="2px"
              style={{ opacity: 0.8 }}
            />
          </Box>
          <Box
            h="40px"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            borderColor="lightNeutrals"
            mB="s"
          >
            <GhostIconButton
              name="clearclose"
              color="inherit"
              iconSize="l"
              onClick={() => dispatch(setIsOpen(false))}
            />
            <Text fontSize="m">Select Language</Text>
            <Text
              fontSize="m"
              color="primary"
              onClick={() => dispatch(setIsOpen(false))}
              style={{ cursor: "pointer" }}
            >
              Done
            </Text>
          </Box>
        </Box>
        <Box d="flex" direction="column" gap="base" mL="s" mT="s">
          {filteredLanguages.map(({ flag, code, name }) => {
            const FlagImage = flag;
            return (
              <Box
                key={code}
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => handleLanguageClick(code)}
                style={{ cursor: "pointer" }}
              >
                <Box d="flex" alignItems="center" gap="base">
                  {SHOW_LANGUAGE_FLAGS && (
                    <img src={FlagImage} alt="flag-icon" />
                  )}
                  <Text
                    fontSize="m"
                    lineHeight="m"
                    fontWeight={
                      currentLanguageCode === code ? "bold" : "normal"
                    }
                    color={currentLanguageCode === code ? "primary" : undefined}
                  >
                    {name}
                  </Text>
                </Box>
                {currentLanguageCode === code && (
                  <Icon name="Check" color="primary" />
                )}
              </Box>
            );
          })}
        </Box>
      </Drawer>
    </>
  );
};

export default LanguageDrawer;
