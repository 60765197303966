import React from "react";
import GlobalStyles from "components/smaltOverwritten/GlobalStyles";
import { FLIGHTS_LIST_POLLING_INTERVAL } from "configs/constants";
import useWindowDimensions from "hooks/windowDimensions";
import { merge } from "lodash";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import { ThemeProvider } from "styled-components";
import { getFontFamily } from "utils/getFontFamily.util";

import pocketFlightsTheme from "./theme";

const PocketFlightsThemeProvider = ({ children, applyGlobalStyles = true }) => {
  const { data: settingsData = {} } = useGetSettingsQuery(undefined, {
    refetchOnReconnect: true,
    pollingInterval: FLIGHTS_LIST_POLLING_INTERVAL,
  });

  const { width } = useWindowDimensions();
  const { theme } = settingsData;

  const UpdatedTheme = merge({}, pocketFlightsTheme, {
    colors: {
      primary: theme?.primary_color || "#575FCC",
      focus: theme?.primary_color || "#575FCC",
      secondary: theme?.secondary_color || "#EDEDED",
      accent_1: theme?.accent_color || "#212151",
      accent_2: theme?.accent_color_2 || "#FFDA1A",
    },
    fontFamily: {
      base: {
        name: getFontFamily(window._env_.REACT_APP_REALM),
      },
    },
    logo: {
      realmLogoWidth: theme?.logo_width || 68,
    },
    tabs: {
      // Prevent tabs to overflow for smaller phones
      // TODO Find a better way to handle tabs overflowing
      fontSize: (() => {
        if (width <= 341) return "13px";
        else if (width < 362) return "14px";
        else if (width < 380) return "15px";
        else return "16px";
      })(),
    },
  });

  return (
    <ThemeProvider theme={UpdatedTheme}>
      {applyGlobalStyles ? <GlobalStyles /> : null}
      {children}
    </ThemeProvider>
  );
};

export default PocketFlightsThemeProvider;
